<template>
<main-layout>
  <div id="personal">
    <div class="head">
      <div class="left">
        <img v-if="photo" :src="photo" class="photo"/>
        <img v-else class="photo" src="@/assets/iotmobile_logo.png"/>
      </div>
      <div class="right">
        <div class="info">
          <div>欢迎: {{nickName?nickName:'新用户'}}</div>
          <div style="font-size:13px;color:#eee;margin-top:5px">IOT平台</div>
        </div>
        <div class="tools">
          <img class="setting" src="@/assets/icon/setting.png" v-on:click="goSetting"/>
        </div>
      </div>
    </div>
    <div class="warpper">
      <div class="">
        <div class="item" v-if="isLogin" v-on:click="gotoOrder">
          <div class="label">我的社区</div>
          <div class="icon"><i class="right"></i></div>
        </div>
         <!-- <div class="item" v-if="isLogin" v-on:click="gotoCardOrder">
          <div class="label">我的社区卡</div>
          <div class="icon"><i class="right"></i></div>
        </div> -->
         <div class="item" v-if="isLogin" v-on:click="goFavorite">
          <div class="label">我的收藏</div>
          <div class="icon"><i class="right"/></div>
        </div>
        <div class="item" v-on:click="gotoFeedback">
          <div class="label">意见反馈</div>
          <div class="icon"><i class="right"/></div>
        </div>
         <div class="item" v-on:click="gotoAbout">
          <div class="label">关于我们</div>
          <div class="icon"><i class="right"/></div>
        </div>
        <!-- <div class="item" v-if="isLogin" v-on:click="gotoAddress">
          <div class="label">收货地址</div>
          <div class="icon"><i class="right"></i></div>
        </div>
        <div class="item">
          <div class="label" v-on:click="gotoHelp">帮助中心</div>
          <div class="icon"><i class="right"></i></div>
        </div> -->
      </div>
      <div v-if="isLogin" style="padding:40px 20px;text-align:center;">
        <div class="bt" v-on:click="logout">退出登陆
        </div>
      </div>
      <div class="more">v1.0.0</div>
    </div>
  </div>
</main-layout>
</template>
<script>
import MainLayout from '../layouts/Main'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'App',
  computed: {
      ...mapState({
          isLogin: state => state.user.isLogin,
          userName: state => state.user.userName,
          token: state => state.user.token,
          photo: state => state.user.photo,
          nickName: state => state.user.nickName,
      }),
  },
  data () {
      return {
       //   userName1:this.state.user.userName,
      }
  },
  methods:{
     gotoOrder:function(){
       this.$router.push({name:'order'});
     },
     gotoCardOrder:function(){
       this.$router.push({name:'cardorder'});
     },
     gotoAbout:function(){
       this.$router.push({name:'about'});
     },
     gotoFeedback:function(){
       this.$router.push({name:'feedback'});
     },
     goSetting(){
       this.$router.push({name:'setting'});
     },
     goFavorite(){
       this.$router.push({name:'favorite'});
     },
     logout:function(){
       fetch(this.global.apiHost+"/api/auth/logout",
        {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (data.Success) {
                this.$store.dispatch('user/logout');
                history.back();
            }
            else{

            }
        }).catch(e => {
            console.log(e);
        });
     }
  },
  created: function () {
    console.log(this.$store.user);
  },
  mounted: function () {//dom已渲染
      if(!this.isLogin){
        this.$router.push({ name: 'login'})
      }
      else{
          this.$store.dispatch('user/getUserInfo');
      }
  },
  components: {
    MainLayout
  }
}
</script>
<style lang="css" scoped>
#personal{
  height: 100vh;
}
.head{
  background: #ff3333;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.head .left{
    width: 100px;
    height: 100%;
    display: flex;
    padding:20px;
    align-items: center;
}

.head .right{
  padding: 0 20px 0 0px;
  display: flex;
  justify-content: center;
}
.head .right .info{
  display: flex;
  flex: 1;
  color:#fff;
  padding: 5px 0;
  font-size: 14px;
  flex-direction: column;
}
.head .right .tools{
  width: 80px;
  padding: 5px;
  display: flex;
    justify-content: flex-end;
}
.head .right .tools img{
  width: 32px;
  height: 32px;
}
.head .left .photo{
  width:80px;
  height:80px;
  border-radius: 40px;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
}
.warpper{
  height: 100%;
  display:flex;
  flex: 1;
  flex-direction: column;
}
.warpper .item{
  border-bottom: 1px #ddd solid;
  height: 60px;
  display: flex;
  flex-direction: row;
  line-height: 60px;
  background: #fff;
}
.warpper .item .label{
  flex: 1;
  align-items: center;
  justify-content: end;
  margin-left: 20px;
  color:#333;
  font-size: 14px;
}
.warpper .item .icon{
   width:40px;
}
.icon i{
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
}
i.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.more {
  text-align: center;
  color: #aaa;
  font-size: 12px;
  position: absolute;
  width: 100%;
  bottom: 80px;
  z-index: 1;
}

.bt {
    background: #ff3333;
    line-height: 42px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    font-family: PingFangSC-Light,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,STHeitiSC-Light,Microsoft YaHei,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    border-radius: 20px;
}

</style>
